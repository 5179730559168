/**
 * @file Layout.js
 * @brief This is a layout component that wraps around pages to provide a consistent layout.
 * @param {ReactNode} children - The child components to be rendered inside the layout.
 * @param {Object} location - The location object containing information about the current URL.
 */

import React from "react"
import PropTypes from "prop-types"
import { Box, ThemeProvider } from "theme-ui"
import { AnimatePresence, motion } from "framer-motion"

// Import custom components
import styles from "./layoutStyles"
import theme from "../../gatsby-plugin-theme-ui"
import BottomBar from "../../components/bottomBar/BottomBar"
import Header1 from "../../sections/headerSection/Header-1"
import Footer1 from "../../sections/footerSection/Footer-1"
import TopBar1 from "../../sections/topBarSection/TopBar-1"
import TopBar2 from "../../sections/topBarSection/TopBar-2"

const Layout = ({ children, location }) => (
  // ThemeProvider wraps all child components to provide the theme context
  <ThemeProvider theme={theme}>
    {/* Main layout container */}
    <Box sx={styles.layout}>
      {/* TopBar components */}
      <TopBar2 />
      <TopBar1 />

      {/* Header component */}
      <Header1 />

      {/* Main content area */}
      <Box as="main" sx={styles.main}>
        {/* AnimatePresence manages the animation for its children */}
        <AnimatePresence mode="wait">
          {/* motion.div for animated transitions */}
          <motion.div
            key={location.pathname} // Unique key based on the current path
            initial={{ opacity: 0 }} // Initial state
            animate={{ opacity: 1 }} // Final state
            exit={{ opacity: 0 }} // Exit state
            transition={{ duration: 0.1 }} // Animation transition duration
          >
            {/* Render the children components */}
            {children}
          </motion.div>
        </AnimatePresence>
      </Box>

      {/* Footer component */}
      <Footer1 />

      {/* BottomBar component */}
      <BottomBar />
    </Box>
  </ThemeProvider>
)

// PropTypes for type checking during development
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
