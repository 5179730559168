/**
 * @jsxImportSource theme-ui
 */
import React from "react"
import { Box, Text } from "theme-ui"
import { topBarStyle } from "./topBar-1-Styles"

/**
 * @description Top Bar component.
 * Displays a top bar with address information.
 */
const TopBar2 = () => {
  return (
    <Box
      sx={{
        ...topBarStyle,
        background: "primary",
        color: "background",
        textAlign: "center",
      }}
    >
      <Text>
        <strong>
          $100 Donation to the{" "}
          <a href="https://www.youtube.com/watch?v=t5fH8UYBVmk">
            Green Olive Project
          </a>{" "}
          with each sale!
        </strong>{" "}
        | Email: Legacyautomotive@me.com
      </Text>
    </Box>
  )
}

export default TopBar2
